@import '../../../style/var.colors.scss';

.root {
	margin-bottom: 30px;
}

.label {
	color: #555555; /*#7f8590;*/
	margin-bottom: 5px;
	font-weight: bold;
}

.popoverTarget {
	color: $primary;
	margin-left: 5px;
}

.popoverContent {
	padding: 9px 14px;
	background-color: rgb(240, 242, 242);
	border-color: #000000;
	min-width: 20rem;
}

.popoverText {
	margin: 0;
}

.tooltipBackground {
	background: lightgray !important;
	color: black !important;
}
