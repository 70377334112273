.root {
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #ccc;
	font-family: inherit;
	font-size: 12px;
	line-height: 1.28571429;
	height: 28px;
	border-radius: 3px;
	padding: 4px;
	outline: 0;
}

.bordered:focus {
	border: 2px solid #4c9aff;
}

.invalid {
	border: 2px solid #d9534f;
}

.lg {
	font-size: 15px;
	height: 40px !important;
}

.readonly {
	background: #f2f2f2;
	border-color: #e6e6e6;
	pointer-events: none;
	color: #999999;
}

.inputAddon {
	padding: 5px 0 5px 4px;
	background: #e9ecef;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	border: 1px solid #ccc;
	border-left: 0;
	text-align: center;
}

.noRightRadius {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}
