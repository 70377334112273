.root {
	border: none;
	padding: 4px 8px;
	text-align: left;
}

.root:hover {
	background-color: #f5f5f5;
	color: #333;
	cursor: pointer;
}

.active {
	background-color: #f5f5f5;
	color: #333;
	cursor: pointer;
}
