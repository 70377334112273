.root {
	position: absolute;
	box-sizing: border-box;
	padding: 0px;
	margin: 0;
	width: 200px;
	background-color: #f4f5f7;
	right: 30px;
	top: 65px;
	border-radius: 4px;
	display: none;
	height: 300px;
	overflow-y: auto;
}

.root ul {
	padding: 0px;
	margin: 0px;
}

.open {
	display: inline-block;
}

.items {
	list-style-type: none;
	font-size: 14px;
	width: 100%;
	padding: 5px 5px 5px;
	box-sizing: border-box;

	a {
		cursor: pointer;
	}
}

.icon {
	text-align: center;
}

.items:hover {
	background-color: #eaecef;
}

.href {
	color: #505f79;
}
