.root {
	position: relative;
	bottom: 0;
	width: 100%;
	border-top: 1px solid #e7eaec;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
	.root {
		width: 86%;
		left: 84px;
	}
}
