.root {
	border: none;
	padding: 4px 8px;
	text-align: left;
}

.date {
	// text-align: right;
	color: #999;
}

.bordered {
	border: 1px solid #ccc;
}
