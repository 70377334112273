.strengthMeter {
	position: absolute;
	bottom: 2px;
	background-color: white;
	border-left: solid 1px #d2d2d2;
	border-right: solid 1px #d2d2d2;
	border-bottom: solid 1px #d2d2d2;
	border-top: solid 1px #d2d2d2;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 0px;
	width: 100%;
}

.items {
	margin-bottom: 5px;
}
