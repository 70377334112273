.root {
	text-align: center;
	padding: 33px 0px;
	padding-bottom: 15px;
}

.userName {
	font-size: 12px;
	color: #424a60;
	text-decoration: underline;
	font-weight: bold;
}
.usernameWelcome {
	font-size: 12px;
	color: #424a60;
}
.root img {
	max-width: 90px;
}
