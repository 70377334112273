.root {
	background: transparent;
	border-bottom: 1px solid #ccc;
	color: #333;
	border-left: 2px solid transparent;
	line-height: 2rem;
	cursor: pointer;
}

.dangerLine {
	color: #d04437;
}

.dangerLineBack {
	background-color: #d04437;
	color: #ffffff;
	opacity: 0.8;
}

.dangerLineBack a {
	color: #ffffff !important;
}
