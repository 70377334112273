@import '../../style/var.colors.scss';

.root {
	background-color: #d1dade;
	color: #5e5e5e;
	font-size: 10px;
	font-weight: 600;
	padding: 3px 8px;
	text-shadow: none;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25em;
}

.lg {
	font-size: 0.8rem;
	padding: 7px 15px;
}

.primary {
	background: $primary;
	color: #fff;
}

.success {
	background: $success;
	color: #fff;
}

.medium {
	background: #ffc300;
	color: #fff;
}

.danger {
	background: $danger;
	color: #fff;
}

.warning {
	background: #ecae68;
	color: #fff;
}

.critical {
	background: $critical;
	color: #fff;
}
