@import '../../../style/var.colors.scss';

.root {
	position: relative;
	display: block;
	padding: 0;
}

.root a {
	position: relative;
	display: flex;
	flex: none;
	align-items: center;
	color: $sidebarMenuItem;
	padding: 8px 12px 8px 12px;
	border-radius: 3px;
	box-sizing: border-box;
	height: 40px;
}

.selected {
	background-color: rgba(9, 30, 66, 0.04);
	color: #0052cc !important;
}

.image {
	height: 20px;
	width: 20px;
}
