.root {
	list-style: none;
	padding: 0;
	line-height: 1.85714286;
	margin: 10px 0 0;
	border-collapse: collapse;
	width: 100%;
	margin-top: 20px;
}

/*
.root th:last-child {
  text-align: right;
}*/
