.root {
	background: #d04437;
	color: #ffffff;
	opacity: 0.9;
	border-radius: 3px;
	line-height: 20px;
	overflow-wrap: break-word;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-top: 10px;
	position: relative;
	word-wrap: break-word;
	border: 1px solid #d04437;
}

.success {
	background: #dff0d8;
	color: #3c763d;
	border: 1px solid #d0e9c6;
}
