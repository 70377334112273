@import '../../../style/var.colors.scss';

.root {
	box-sizing: border-box;
	border-radius: 3px;
	cursor: pointer;
	display: inline-block;
	font-family: inherit;
	font-size: 14px;
	font-variant: normal;
	font-weight: 400;
	margin: 0;
	text-decoration: none;
	vertical-align: baseline;
	white-space: nowrap;
	color: #505f79;
	background: #f4f5f7;
	border: none;
	outline: 0;
	height: 2.28571429em;
	line-height: 1.57142857em;
	padding-top: 5px;
	padding-bottom: 5px;
}

.root:hover {
	background: #ebecf0;
	text-decoration: none;
}

.primary {
	background: $primary;
	color: #ffffff;
	font-weight: 700;
}

.primary:hover {
	background: $primaryHover;
}

.danger {
	background: $danger;
	color: #fff;
	font-weight: 700;
}

.danger:hover {
	background: $dangerHover;
}

.readonly {
	background: #ccc;
	border-color: #ccc;
	cursor: default;
	pointer-events: none;
}

.readonly:hover {
	background: #ccc;
	border-color: #ccc;
}

.lg {
	height: 45px;
	font-size: 16px;
	margin-top: 15px;
	margin-bottom: 15px;
}
