.root {
}

.label {
	width: 100%;
	cursor: pointer;
}

.btnText {
	margin-left: 8px;
}

.img {
	max-width: 100%;
	object-fit: cover;
}
