.root {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background-color: #ffffff;
	border-color: #e7eaec;
	border-image: none;
	border-style: solid solid none;
	border-width: 4px 0px 0;
	color: inherit;
	margin-bottom: 0;
	padding: 14px 15px 7px;
}

.label {
	color: #7f8590;
	margin-bottom: 1.5rem;
	font-size: 1.2rem;
}
