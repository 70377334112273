.root {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.errorPage {
	width: 100%;
	max-width: 750px;
	background: #fff;
	text-align: center;
	padding: 25px;
}

.errorCode {
	color: #b2bccb;
	font-size: 150px;
	font-weight: 800;
	line-height: 1;
}

.errorHeader {
	font-size: 22px;
	font-weight: 600;
	color: #37404c;
	margin-top: 10px;
	margin-bottom: 20px;
}
