.react-tabs {
	&__tab-list {
		border-bottom: 1px solid #aaa;
		margin: 0 0 10px;
		padding: 0;
	}

	&__tab {
		display: inline-block;
		border: 1px solid transparent;
		border-bottom: none;
		bottom: -1px;
		position: relative;
		list-style: none;
		padding: 0.5rem 2rem;
		cursor: pointer;

		&--selected {
			background: #fff;
			border-color: #ccc;
			color: black;
			border-radius: 2px 2px 0 0;
		}

		&--disabled {
			color: GrayText;
			cursor: default;
		}

		&:focus {
			outline: none;
		}
	}

	&__tab-panel {
		display: none;

		&--selected {
			display: block;
		}
	}
}
