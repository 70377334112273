.dropdown {
	display: block;

	ul {
		background: #fff;
		list-style: none;
		padding-left: 15px;
		margin: 0;

		li {
			padding-bottom: 7px;

			span {
				margin-left: 10px;
			}
		}

		&:first-child {
			padding-top: 10px;
			padding-bottom: 10px;

			li {
				cursor: pointer;
			}
		}
	}
}

.dropdown__content {
	display: none;
	position: absolute;
	width: 100%;
	z-index: 20;
}

.dropdown--active .dropdown__content {
	display: block;
}
