.root {
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	.columnResponsive {
		height: 48px;
		line-height: 48px;
	}
}
@media screen and (min-width: 320px) and (max-width: 380px) {
	.columnResponsive {
		height: 48px;
		font-size: 12px;
		line-height: 14px;
	}
}
