.root {
	background: #fff;
	border-bottom: 1px solid #ccc;
	color: #333;
	// border-left: 2px solid transparent;
	line-height: 1rem;
}

.dangerLine {
	color: #d04437;
}

.dangerLineBack {
	background-color: #d04437;
	color: #ffffff;
	opacity: 0.8;
}

.dangerLineBack a {
	color: #ffffff !important;
}

.hovered {
	background-color: #f4f5f7;
}
