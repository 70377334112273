.bellCount {
	position: absolute;
	top: -17px;
	display: inline-block;
	left: 10px;
	color: white;
	font-size: 9px;
	font-weight: bold;
	background-color: hsl(253, 67%, 63%);
	border-radius: 2px;
	min-width: 14px;
	min-height: 14px;
	line-height: 12px;
	text-align: center;
	padding: 2px;
}

.bell {
	display: block;
	-webkit-animation: ring 4s 0.7s ease-in-out infinite;
	-webkit-transform-origin: 30% 4px;
	-moz-animation: ring 4s 0.7s ease-in-out infinite;
	-moz-transform-origin: 30% 4px;
	animation: ring 4s 0.7s ease-in-out infinite;
	transform-origin: 30% 4px;
}

@-webkit-keyframes ring {
	0% {
		-webkit-transform: rotateZ(0);
	}
	1% {
		-webkit-transform: rotateZ(30deg);
	}
	3% {
		-webkit-transform: rotateZ(-28deg);
	}
	5% {
		-webkit-transform: rotateZ(34deg);
	}
	7% {
		-webkit-transform: rotateZ(-32deg);
	}
	9% {
		-webkit-transform: rotateZ(30deg);
	}
	11% {
		-webkit-transform: rotateZ(-28deg);
	}
	13% {
		-webkit-transform: rotateZ(26deg);
	}
	15% {
		-webkit-transform: rotateZ(-24deg);
	}
	17% {
		-webkit-transform: rotateZ(22deg);
	}
	19% {
		-webkit-transform: rotateZ(-20deg);
	}
	21% {
		-webkit-transform: rotateZ(18deg);
	}
	23% {
		-webkit-transform: rotateZ(-16deg);
	}
	25% {
		-webkit-transform: rotateZ(14deg);
	}
	27% {
		-webkit-transform: rotateZ(-12deg);
	}
	29% {
		-webkit-transform: rotateZ(10deg);
	}
	31% {
		-webkit-transform: rotateZ(-8deg);
	}
	33% {
		-webkit-transform: rotateZ(6deg);
	}
	35% {
		-webkit-transform: rotateZ(-4deg);
	}
	37% {
		-webkit-transform: rotateZ(2deg);
	}
	39% {
		-webkit-transform: rotateZ(-1deg);
	}
	41% {
		-webkit-transform: rotateZ(1deg);
	}

	43% {
		-webkit-transform: rotateZ(0);
	}
	100% {
		-webkit-transform: rotateZ(0);
	}
}

@-moz-keyframes ring {
	0% {
		-moz-transform: rotate(0);
	}
	1% {
		-moz-transform: rotate(30deg);
	}
	3% {
		-moz-transform: rotate(-28deg);
	}
	5% {
		-moz-transform: rotate(34deg);
	}
	7% {
		-moz-transform: rotate(-32deg);
	}
	9% {
		-moz-transform: rotate(30deg);
	}
	11% {
		-moz-transform: rotate(-28deg);
	}
	13% {
		-moz-transform: rotate(26deg);
	}
	15% {
		-moz-transform: rotate(-24deg);
	}
	17% {
		-moz-transform: rotate(22deg);
	}
	19% {
		-moz-transform: rotate(-20deg);
	}
	21% {
		-moz-transform: rotate(18deg);
	}
	23% {
		-moz-transform: rotate(-16deg);
	}
	25% {
		-moz-transform: rotate(14deg);
	}
	27% {
		-moz-transform: rotate(-12deg);
	}
	29% {
		-moz-transform: rotate(10deg);
	}
	31% {
		-moz-transform: rotate(-8deg);
	}
	33% {
		-moz-transform: rotate(6deg);
	}
	35% {
		-moz-transform: rotate(-4deg);
	}
	37% {
		-moz-transform: rotate(2deg);
	}
	39% {
		-moz-transform: rotate(-1deg);
	}
	41% {
		-moz-transform: rotate(1deg);
	}

	43% {
		-moz-transform: rotate(0);
	}
	100% {
		-moz-transform: rotate(0);
	}
}

@keyframes ring {
	0% {
		transform: rotate(0);
	}
	1% {
		transform: rotate(30deg);
	}
	3% {
		transform: rotate(-28deg);
	}
	5% {
		transform: rotate(34deg);
	}
	7% {
		transform: rotate(-32deg);
	}
	9% {
		transform: rotate(30deg);
	}
	11% {
		transform: rotate(-28deg);
	}
	13% {
		transform: rotate(26deg);
	}
	15% {
		transform: rotate(-24deg);
	}
	17% {
		transform: rotate(22deg);
	}
	19% {
		transform: rotate(-20deg);
	}
	21% {
		transform: rotate(18deg);
	}
	23% {
		transform: rotate(-16deg);
	}
	25% {
		transform: rotate(14deg);
	}
	27% {
		transform: rotate(-12deg);
	}
	29% {
		transform: rotate(10deg);
	}
	31% {
		transform: rotate(-8deg);
	}
	33% {
		transform: rotate(6deg);
	}
	35% {
		transform: rotate(-4deg);
	}
	37% {
		transform: rotate(2deg);
	}
	39% {
		transform: rotate(-1deg);
	}
	41% {
		transform: rotate(1deg);
	}

	43% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.bellCount {
		top: -11px;
		left: 5px;
		min-width: 10px;
		min-height: 10px;
		line-height: 10px;
	}
}

.requiredIcon {
	color: rgb(255, 95, 95);
}

.addWidgetBtn {
	color: rgb(103, 106, 108) !important;
	padding: 0;
	cursor: pointer;
	margin-bottom: 1em !important;
}

.m1 {
	margin: 1em;
}

.mb5 {
	margin-bottom: 5px;
}

.card1Row {
	flex: 0 0 100%;
	max-width: 100%;
}

.cardWrapper {
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}

.cardHeaderContent {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	color: rgb(103, 106, 108) !important;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	font-size: 14px !important;
	font-family: Arial !important;
	font-weight: 600 !important;
}

.cardBodyContent {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 0.5rem;
}

.formBtn {
	display: inline-block !important;
	font-weight: 400 !important;
	text-align: center !important;
	vertical-align: middle !important;
	user-select: none !important;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem !important;
	font-size: 1rem !important;
	line-height: 1.5 !important;
	border-radius: 0.25rem !important;
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out !important;
}

.addNewWidgetBtnSection {
	right: 10px;
	display: flex;
	z-index: 999;
	overflow: hidden;
	position: absolute;
	flex-direction: column;
}

.widgetCloseBtn {
	cursor: pointer !important;
	color: rgb(103, 106, 108) !important;
	padding-right: 5px;
	padding-top: 3px;
	float: right !important;
	visibility: hidden;
}

.widgetEditBtn {
	cursor: pointer !important;
	color: rgb(103, 106, 108) !important;
	padding-right: 5px;
	padding-top: 3px;
	float: right !important;
	visibility: hidden;
}

.widgetCloseBtnForChartMap {
	cursor: pointer !important;
	color: rgb(103, 106, 108) !important;
	padding-right: 5px;
	padding-top: 3px;
	float: right !important;
}

.widgetEditBtnForChartMap {
	cursor: pointer !important;
	color: rgb(103, 106, 108) !important;
	padding-right: 5px;
	padding-top: 3px;
	float: right !important;
}

.formSection {
	overflow-y: auto !important;
	max-height: 43.7em !important;
}

.tooltipTableRow {
	cursor: pointer !important;
	border: none !important;
	padding: 8px 8px !important;
	text-align: left !important;
}

.tooltipTable {
	margin-top: -10px !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
	.widgetLeftText {
		font-size: 12px;
	}
}
