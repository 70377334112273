@import '../../../style/var.colors.scss';

.root {
	box-sizing: border-box;
	border-radius: 3px;
	cursor: pointer;
	display: inline-block;
	font-family: inherit;
	font-size: 14px;
	font-variant: normal;
	font-weight: 400;
	margin: 0;
	text-decoration: none;
	vertical-align: baseline;
	white-space: nowrap;

	color: #505f79;
	background: #f4f5f7;
	border: none;
	outline: 0;
	height: 2.28571429em;
	line-height: 1.57142857em;
	padding-top: 5px;
	padding-bottom: 5px;
}

.root:hover {
	background: #ebecf0;
}

.primary {
	background: $primary;
	color: #fff;
	font-weight: 700;
}

.primary:hover {
	background: $primaryHover;
}

.danger {
	background: $danger;
	color: #fff;
	font-weight: 700;
}

.danger:hover {
	background: $dangerHover;
}

.success {
	background: $success;
	color: #fff;
}

.success:hover {
	background: $successHover;
}

.readonly {
	background: #ccc;
	border-color: #ccc;
	cursor: default;
}

.readonly:hover {
	background: #ccc;
	border-color: #ccc;
}

.lg {
	height: 45px;
	font-size: 16px;
}

.sm {
	height: 20px;
	width: auto !important;
	padding-right: 10px !important;
	padding-left: 10px !important;
	padding-top: 3px !important;
	font-size: 10px;
	font-weight: 600;
}
