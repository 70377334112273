@import '../../../style/var.colors.scss';

.root {
	position: fixed;
	width: 245px;
	height: 100vh;
	background-color: $sidebarBackground;
	transition: width 1s;
	-webkit-transition: width 1s;
	overflow: scroll;
	overflow-x: hidden;
}

.closed {
	width: 84px;
	overflow-x: hidden;
	overflow-y: scroll;
	box-sizing: border-box;
	overflow-x: hidden;
}

.root::-webkit-scrollbar {
	width: 4px;
	color: #a5a5a5;
}

.root::-webkit-scrollbar-thumb:hover {
	background: #999999;
}

.menu {
}

.nav {
	margin: 0;
	padding: 0;
	list-style: none;
}
