/*.root {
  position: absolute;
  box-sizing: border-box;
  padding: 0px;
  margin: 0;
  width: 370px;
  background-color: #f4f5f7;
  right: 110px;
  top: 65px;
  border-radius: 3px;
  display: block;
  overflow-y: auto;
  height: 300px;
  box-shadow: 0px 0px 15px -10px #222;
}*/

.root {
	position: absolute;
	box-sizing: border-box;
	padding: 0px;
	margin: 0;
	min-width: 370px;
	width: 100%;
	background-color: #f4f5f7;
	right: 0;
	top: 30px;
	border-radius: 3px;
	display: block;
	overflow-y: auto;
	height: 300px;
	box-shadow: 0px 0px 15px -10px #222;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
	.root {
		min-width: 220px;
	}
}

.open {
	/* display: inline-block; */
}

.items {
	list-style-type: none;
	font-size: 14px;
	min-height: 50px;
	width: 100%;
	/* border-radius: 5px; */
	padding: 18px 10px;
	box-sizing: border-box;

	&:nth-child(odd) {
		background-color: white;
	}
}

/* .items:hover {
  background-color: #eaecef;
} */

.href {
	color: #505f79;
}
