@import '../../../style/var.colors.scss';

.root {
	box-sizing: border-box;
	border-radius: 3px;
	cursor: pointer;
	display: inline-block;
	font-family: inherit;
	font-size: 14px;
	font-variant: normal;
	font-weight: 700;
	margin: 0;
	text-decoration: none;
	vertical-align: baseline;
	white-space: nowrap;
	color: #fff;
	background: $danger;
	border: none;
	outline: 0;
	height: 2.28571429em;
	line-height: 1.57142857em;
	padding-top: 5px;
	padding-bottom: 5px;
}

.root:hover {
	background: $dangerHover;
}

.readonly {
	background: #ccc;
	border-color: #ccc;
	cursor: default;
}

.readonly:hover {
	background: #ccc;
	border-color: #ccc;
}

.lg {
	height: 45px;
	font-size: 16px;
}

.popupCustom {
	background: red;
}
