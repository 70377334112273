.root {
}

.table {
	width: 100%;
	margin-top: 10px;
	border-spacing: 0;

	tr {
		th {
			text-align: left;
			line-height: 2.7em;
			border-bottom: solid 1px #888;
		}

		td {
			text-align: left;
			line-height: 3em;
		}
	}
}

.tableRow {
	display: flex;
	width: 100%;
	// justify-content: space-between;

	div {
		padding: 15px 5px;
	}
}
