.hidden {
	display: none;
}

.hidden .content {
	transform: translate(-50%, -50%) scale(0.7);
	opacity: 0;
}

.overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(88, 88, 88, 0.5);
}

.content {
	overflow: hidden;
	border-radius: 5px;
	background-color: #ffffff;
	position: fixed;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
	transition: all 0.3s linear;
	width: 90%;
}

@media screen and (min-width: 320px) and (max-width: 540px) {
	.content {
		width: 90%;
	}
}

@media screen and (min-width: 541px) and (max-width: 767px) {
	.content {
		width: 60%;
	}
}