.root {
	border-bottom: 1px solid #e7eaec;
	position: relative;
	min-height: 50px;
}

@media screen and (min-width: 671px) and (max-width: 767px) {
	.logoMain {
		width: 100px;
	}
}
